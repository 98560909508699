<template>
  <b-card>
    <b-modal no-close-on-backdrop no-close-on-esc size="sm" v-model="editImg" @close="closeModal" hide-footer centered>
      <img class="imgEdit mb-1" :src="tagImg.value" />

      <validation-provider #default="{ errors }" :name="tagImg.field" rules="required">
        <div>
          <label for="Tag">{{ tagImg.field }}</label>
          <b-form-input id="Tag" v-model="tagImg.field" :name="tagImg.type" />
          <b-card v-if="searchImage(tagImg.value)">
            <b-card-title>Calidad: {{ quality }}%</b-card-title>

            <vue-slider v-model="quality" :min="10" :max="100" :interval="1" />
            <b-form-radio-group id="format-options" v-model="format" class="radio mt-1">
              <b-form-radio v-for="option in formatOptions" :key="option.value" :value="option.value" :class="`custom-control-${option.radioVariant}`">
                {{ option.text }}
              </b-form-radio>
            </b-form-radio-group>
          </b-card>
        </div>

        <b-button
          class="mb-2 mt-2 btnUpload"
          :disabled="!tagImg.field || $apiCall.state"
          @click="setOptions(format, quality)"
          block
          variant="success"
          type="button"
        >
          <feather-icon size="25" class="mr-1" icon="UploadCloudIcon" />
          <h6 class="titulo_btnUpload" v-if="searchImage(tagImg.value)">Subir Imagen</h6>
          <h6 class="titulo_btnUpload" v-if="!searchImage(tagImg.value)">Actualizar</h6>

          <b-spinner v-if="load" class="mt-2 spinner-avatar" small variant="info" type="border"> </b-spinner>
        </b-button>

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-modal>

    <div class="gallery text-center mb-3">
      <div md="2" v-for="(data, i) in listImages" :key="i">
        <div class="hovereffect mx-auto">
          <b-modal hide-footer centered v-model="deleteModal">
            <h2>Seguro que desea eliminar la imagen?</h2>
            <b-row class="mt-2">
              <b-col class="">
                <b-button class="mb-2" @click="deleteImg(data, i)" block variant="success" type="button">
                  Aceptar
                  <b-spinner v-if="load" class="spinner-avatar" small variant="info" type="border"> </b-spinner>
                </b-button>
              </b-col>

              <b-col>
                <b-button class="mb-2" @click="deleteModal = false" block variant="warning" type="button"> Cancelar </b-button>
              </b-col>
            </b-row>
          </b-modal>

          <img class="imgData" :src="data.value" alt="" />

          <div class="overlay">
            <p>
              <a @click="editTag(data)"> <feather-icon size="32" class="iconEdit" icon="EditIcon" /></a>
            </p>

            <p>
              <a @click="modalDelete(data, i)"> <feather-icon size="34" class="iconDelete" icon="Trash2Icon" /></a>
            </p>
          </div>
        </div>
        <h5 v-if="data.value" class="mx-0 mt-1">
          {{ data.field }}
        </h5>
      </div>

      <div class="addImage">
        <input id="cameraGroupImage" style="display: none" type="file" accept="image/* capture=camera" @change="getFile()" />
        <label class="lbl" for="cameraGroupImage">
          <img src="@/assets/images/icons/upload-image-icon.png" class="iconAddImage" alt="" />
        </label>
      </div>
    </div>

    <b-row v-for="(elem, i) in card" :key="i">
      <b-col class="mt-2" cols="12" md="12" v-if="elem.type.toUpperCase() === 'INPUT'">
        <b-form-group :label-for="elem.field">
          <h5>
            <b-row>
              <b-col cols="8" md="9">
                {{ elem.field }}
              </b-col>
              <b-col cols="1" md="1">
                <b-button
                  :hidden="$session.user.role === 'EDITOR'"
                  class="btn-icon rounded-circle"
                  variant="flat-danger"
                  @click="deleteGroupField(elem)"
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
              </b-col>
              <b-col cols="1" md="1">
                <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="translate(elem)">
                  <feather-icon icon="GlobeIcon" />
                </b-button>
              </b-col>
              <b-col cols="1" md="1">
                <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="copy(elem)">
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </b-col>
            </b-row>
          </h5>
          <validation-provider #default="{ errors }" :name="elem.field" rules="required">
            <b-form-input :id="elem.field" v-model="elem.value" :placeholder="elem.placeholder || ''" :name="elem.field" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" v-if="elem.type.toUpperCase() === 'EDITOR'">
        <b-form-group :label-for="elem.field">
          <h5>
            <b-row>
              <b-col cols="8" md="9">
                {{ elem.field }}
              </b-col>
              <b-col cols="1" md="1">
                <b-button
                  :hidden="$session.user.role === 'EDITOR'"
                  class="btn-icon rounded-circle"
                  variant="flat-danger"
                  @click="deleteGroupField(elem)"
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
              </b-col>
              <b-col cols="1" md="1">
                <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="translate(elem)">
                  <feather-icon icon="GlobeIcon" />
                </b-button>
              </b-col>
              <b-col cols="1" md="1">
                <b-button class="btn-icon rounded-circle" variant="flat-primary" @click="copy(elem)">
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </b-col>
            </b-row>
          </h5>
          <validation-provider #default="{ errors }" :name="elem.field" rules="required">
            <quill-editor v-model="elem.value">
              <div id="toolbar" slot="toolbar"></div>
            </quill-editor>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-button block variant="primary" @click="closeEdit()"> <feather-icon icon="CornerUpLeftIcon" /> Volver </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Uploader from 'vue-media-upload'

import {
  BCarousel,
  BCarouselSlide,
  BCardText,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BListGroup,
  BCardTitle,
  BImg,
  BAvatar,
  BModal,
  BFormRadio,
  BFormRadioGroup,
  BListGroupItem,
  BSpinner
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import VueSlider from 'vue-slider-component'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
export default {
  components: {
    BSpinner,
    Uploader,
    BCard,
    BForm,
    BAvatar,
    BRow,
    BImg,
    BModal,
    VueSlider,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    BCardCode,
    BCarousel,
    BCarouselSlide,
    BCardText,
    BCardTitle,
    ValidationProvider,
    ValidationObserver
  },

  props: {
    block: {
      type: String,
      default: ''
    },
    card: {
      type: Array,
      default: () => []
    },
    pageName: {
      type: String,
      default: 'home'
    },
    parentField: {
      type: String,
      default: ''
    }
  },

  model: {
    prop: 'card',
    event: 'cardChanged'
  },

  data() {
    return {
      load: false,
      media: [],
      quality: 90,
      deleteModal: false,
      format: 'WEBP',
      formatOptions: [
        { text: 'WebP', value: 'WEBP', radioVariant: 'primary' },
        { text: 'Avif', value: 'AVIF', radioVariant: 'primary' }
      ],
      preventtag: '',
      editImg: false,
      tagImg: '',
      img: '',
      newImage: false,
      prevDelete: {
        data: {
          field: '',
          i: 0,
          type: '',
          value: ''
        },
        i: ''
      }
    }
  },

  computed: {
    story() {
      return this.$store.state.storyStore.story
    },
    listImages() {
      return this.card.filter(e => e.type === 'IMAGES' && e.i === this.card[0].i && e.value)
    },
    page() {
      return this.$store.state.storyStore.story.page.find(e => e.name === this.pageName)
    },
    openCard: {
      get() {
        return this.card
      },
      set(value) {
        this.$emit('cardChanged', value)
      }
    }
  },
  methods: {
    closeModal() {
      console.log('CERRO MODAL')
    },
    searchImage(imgText) {
      if (imgText && imgText.includes('https://')) {
        return false
      } else {
        return true
      }
    },
    modalDelete(data, i) {
      this.prevDelete = { data: data, i: i }
      this.deleteModal = true
    },

    async deleteImg() {
      this.load = true
      await this.$store.dispatch('storyStore/deleteImageGroupValue', {
        imageGroupValue: this.prevDelete.data,
        storyId: this.story._id,
        parentField: this.parentField,
        block: this.block,
        pageName: this.pageName
      })
      this.load = false
      this.deleteModal = false
    },

    clickIcon() {
      console.log('ICON')
    },

    changeMedia(media) {
      this.media = media
    },

    async setOptions(format, quality) {
      console.log('this.tagImg :>> ', this.tagImg)
      console.log('nueva')
      const self = this
      let isFirst = false
      if (!self.card[0].value) {
        isFirst = true
      }
      this.load = true
      await this.$store
        .dispatch('storyStore/saveImageGroupValue', {
          isFirst,
          storyId: this.story._id,
          parentField: this.parentField,
          block: this.block,
          pageName: this.pageName,
          options: { format: format, quality: quality },
          imageGroupValue: { ...this.tagImg }
        })
        .then(res => {
          if (self.block === 'es') {
            const root = self.page.content.filter(e => e.element.find(el => el.type === 'GROUP' && el.field === self.parentField))
            root.forEach(el => {
              const elemRoot = el.element.find(f => f.field === self.parentField).groupValue.find(e => e.find(l => l.i === self.card[0].i))
              const emp = elemRoot.find(em => !em.value && em.type === 'IMAGES')
              if (emp) {
                emp.value = res.value
              } else if (this.tagImg._id) {
                const update = elemRoot.find(chg => chg._id.toString() === res._id.toString())
                if (update) {
                  update.field = res.field
                }
              } else {
                elemRoot.push({ /* ...this.tagImg, value: res.value */ ...res })
              }
            })
          } else {
            const empt = self.card.find(em => !em.value && em.type === 'IMAGES')
            if (empt) {
              empt.value = res.value
            } else if (this.tagImg._id) {
              let update = self.card.find(chg => chg._id.toString() === res._id.toString())
              update = { ...res }
            } else {
              self.card.push({ /* ...this.tagImg, value: res.value */ ...res })
            }
          }
          this.load = false
          this.editImg = false
        })
    },

    editTag(data = null) {
      if (data) {
        this.tagImg = data
      }
      this.quality = 40
      this.format = 'WEBP'
      this.editImg = true
    },

    translate(elem) {
      this.$emit('onTranslate', elem)
    },

    copy(elem) {
      this.$emit('onCopy', elem)
    },

    closeEdit() {
      this.$emit('closeEdit', false)
    },

    deleteGroupField(input) {
      const self = this
      this.$swal({
        title: 'Eliminar?',
        text: `Eliminaremos el campo de lista: ${input.field}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          self.openCard = self.openCard.filter(({ field }) => field !== input.field)
          this.$emit('onDeleteGroupField', input.field)
        }
      })
    },

    async getFile() {
      let [files] = document.getElementById('cameraGroupImage').files
      this.render(files)
      files = ''
    },

    async render(file) {
      const self = this
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.addEventListener('load', async e => {
        self.tagImg = { i: self.card[0].i, field: 'IMG', type: 'IMAGES', value: e.target.result }
      })
      return this.editTag()
    }
  }
}
</script>

<style>
.titulo_btnUpload {
  color: white;
}
.imgData {
  max-width: 100%;
  max-height: 100%;
}

.addImage {
  height: 150px;
  cursor: pointer;
  /* background: rgba(5, 151, 242, 0.1); */
}

.addImage :hover {
  cursor: pointer;
  /* opacity: 1; */
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  /* background: rgba(5, 151, 242, 0.9); */
}

.iconAddImage {
  max-height: 150px;
  max-width: 150px;
}
.iconAddImage {
  opacity: 0.2;
}
.radio :hover {
  cursor: pointer;
}
.imgEdit {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
}
.iconDelete:hover {
  color: red;
}
.iconEdit:hover {
  color: #54a7ff;
}

.gallery {
  display: grid;
  gap: 1.2rem 1.2rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.hovereffect {
  height: 150px;
  width: 170px;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  /* border-radius: 10px; */
}

.hovereffect .overlay {
  position: absolute;
  overflow: hidden;
  width: 80%;
  height: 80%;
  left: 10%;
  top: 7%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
}

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
}

.hovereffect img {
  height: 200px;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  /* display: block; */
  /* margin: auto; */
}

.hovereffect:hover img {
  cursor: pointer;
  filter: brightness(0.6);
  -webkit-filter: brightness(0.6);
}

.hovereffect a,
.hovereffect p {
  color: #fff;
  padding: 1em 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.hovereffect:hover a,
.hovereffect:hover p,
.hovereffect:hover h2 {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
</style>
